<template>
  <span>{{ (this.loading || this.record?.loading ? '&nbsp;' : (count || '0')) }}</span>
</template>

<script>
import { PageCount } from '@/models'
import moment from 'moment-timezone'

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    record () {
      return PageCount.find(this.id)
    },
    count () {
      return this.record?.count
    },
    showHidden () {
      return this.$route.query?.show_hidden
    }
  },
  watch: {
    showHidden () {
      this.loadData({ force: true })
    }
  },
  methods: {
    async loadData ({ force = null } = {}) {
      if (!force && this.record?.at && moment(this.record?.at).isAfter(moment().subtract(2, 'minutes'))) {
        return
      }
      this.loading = true
      try {
        await PageCount.fetchOne(this.id, {
          force: true,
          multiplex: true,
          request: {
            params: {
              ...(
                this.showHidden
                ? {
                    show_hidden: true
                }
                : {}
              )
            }
          }
        })
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.loadData()
    this.interval = setInterval(() => {
      this.loadData()
    }, 60000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>