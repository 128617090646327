<template>
  <div>
    <v-tooltip
      bottom
      :disabled="displayName || disableTooltip"
    >
      <template v-slot:activator="{ on }">
        <div
          class="user-avatar d-flex justify-left align-center font-weight-medium"
          style="position: relative;"
          v-on="on"
        >
          <v-progress-circular
            v-if="recordId && loading"
            color="primary"
            indeterminate
          />
          <div
            v-else-if="picture"
            class="avatar-icon"
            :style="{ backgroundImage: `url(${picture})`, width: size + 'px', height: size + 'px' }"
          />
          <div
            v-else
            class="avatar-text"
            :style="{ width: size + 'px', height: size + 'px' }"
          >{{ initials }}</div>
          <div
            v-if="displayName"
            class="ml-4"
          >{{ name }}</div>
        </div>
      </template>
      <div>{{ name }}</div>
      <div v-if="record?.last_seen_at">Last Seen: {{ record?.last_seen_at | recentDate }}</div>
      <div v-else-if="record?.created_at">Invited: {{ record?.created_at | recentDate }}</div>
    </v-tooltip>
  </div>
</template>

<script>
import { User } from '@/models'

export default {
  props: {
    recordId: {
      type: [String, Number],
      default: null
    },
    displayName: {
      type: Boolean,
      default: null
    },
    disableTooltip: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 36
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    record () {
      return User.find(this.recordId)
    },
    picture () {
      return this.record?.picture
    },
    initials () {
      if (!this.recordId) {
        return 'S'
      }
      if (this.record?.name) {
        const fullName = this.record.name.split(' ');
        const initials = fullName[0].charAt(0) + ((fullName.length > 1) ? fullName[1].charAt(0) : '');
        return initials.toUpperCase();
      }
      return '#' + this.recordId
    },
    name () {
      if (!this.recordId) {
        return 'System'
      }
      return this.record?.name || ('#' + this.recordId)
    }
  },
  async created () {
    if (!this.record) {
      this.loading = true
      try {
        if (this.recordId) {
          await User.fetchOne(this.recordId, {
            multiplex: true,
            request: {
              hideErrors: true
            }
          })
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.user-avatar {
  .avatar-icon {
    display: inline-block;
    width: 36px;
    height: 36px;
    background-size: contain;
    border-radius: 18px;
    margin: 1px;
  }
  .avatar-text {
    background: #66ccff;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    margin: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
}
</style>