const privileges = {
  'AccountUser.Create': {},
  'AccountUser.Read': {},
  'AccountUser.Update': {},
  'AccountUser.Delete': {},
  'File.Create': {},
  'File.Delete': {},
  'ImageOptimization.Create': {},
  'ImageOptimization.Read': {},
  'ImageOptimization.Update': {},
  'ImageOptimization.Delete': {},
  'Integration.Create': {},
  'Integration.Read': {},
  'Integration.Update': {},
  'Integration.Delete': {},
  'Page.Create': {
    name: 'Create Page'
  },
  'Page.Read': {
    name: 'Read Page'
  },
  'Page.Update': {
    name: 'Update Page'
  },
  'Page.Delete': {
    name: 'Delete Page'
  },
  'Page.Publish': {
    name: 'Publish Page to Shopify'
  },
  'PageUser.Create': {},
  'PageUser.Read': {},
  'PageUser.Update': {},
  'PageUser.Delete': {},
  'User.Create': {},
  'User.Read': {},
  'User.Update': {},
  'User.Delete': {}
}

module.exports = privileges
