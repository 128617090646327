<template>
  <div
    style="max-width: 500px; margin: 0 auto;"
  >
    <v-card
      v-if="$hasRole('ADMIN')"
    >
      <div
        class="cdseo-grey-bg d-flex"
      >
        <v-card-title
          class="flex-grow-1"
        >Remove Members</v-card-title>
        <div
          v-if="$hasRole('SUPERUSER')"
          class="pa-3"
        >
          <v-btn
            icon
            @click="$store.commit('setSuperuserOverride', true)"
          >
            <v-icon>fa-times</v-icon>
          </v-btn>
        </div>
      </div>
      <v-card-text>
        <v-alert type="error" prominent dense>
          You are exceeding the number of users allowed on your current plan. Please remove {{ $store.getters.planUsersExceeding }} members.
        </v-alert>

        <div
          v-for="accountUser in $store.getters.accountUsers"
          :key="accountUser.id"
        >
          <v-divider />
          <div class="d-flex">
            <div
              class="flex-grow-1 pt-2 font-weight-medium"
            >{{ accountUser.user?.friendlyName || ('User #' + accountUser.user_id) }}<span class="ml-2" v-if="accountUser.user_id === $store.getters.user.id">(You)</span></div>
            <div>
              <v-btn
                
                color="error"
                :disabled="loading || accountUser.user_id === $store.getters.user.id"
                @click="destroy(accountUser)"
              >Remove</v-btn>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card
      v-else
    >
      <v-card-title>Account Is Locked</v-card-title>
      <v-card-text>
        This account is locked and is waiting on an account administrator to take action.
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { AccountUser } from '@/models'

export default {
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async destroy (accountUser) {
      if (!window.confirm(`Are you sure you want to remove ${accountUser.user.friendlyName} from being a member?`)) {
        return
      }
      this.loading = true
      try {
        await AccountUser.destroy(accountUser)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
