<template>
  <v-dialog
    :value="show"
    max-width="600"
  >
    <v-card>
      <v-card-title class="cdseo-grey-bg" style="word-break: break-word;">
        One Last Step ...
      </v-card-title>
      <v-card-text
        class="mt-6"
      >
        <div
          v-if="$store.getters.shopifyIntegration"
        >
          <v-alert type="info" prominent dense class="text-body-2">
            To finalize installation of CDSEO, you need to enable 
            and save the Shopify App Embed Block in your Theme Editor.
          </v-alert>

          <p><strong>Step-by-Step Instructions:</strong></p>
          <ul>
            <li>Click the Theme Editor button below.</li>
            <li class="mt-2">In the Theme Editor, the CDSEO App Embed Block 
              will be automatically enabled.</li>
            <li class="mt-2">Click the Save button at the top right of the 
                Theme Editor to confirm the changes.</li>
            <li class="mt-2">Return to this window and click the Recheck button to verify.</li>
          </ul>

          <v-divider />

          <div>
            <em>This message will no longer appear once the App Embed Block 
              has been successfully enabled.</em>
          </div>
        </div>
        <v-alert type="error" prominent dense
          v-else
        >Shopify Integration Not Found</v-alert>
      </v-card-text>
      <v-card-actions class="cdseo-grey-footer-bg">
        <v-btn
          plain
          @click="show = false"
        >Later</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters.shopifyIntegration"
          large
          secondary
          @click="refreshPage"
        >Recheck</v-btn>
        <v-btn
          large
          :href="deepLinkUrl"
          color="primary"
          target="_blank"
        ><v-icon left>fa-brands fa-shopify</v-icon>Theme Editor</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Integration } from '@/models'

const themeAppUuid = '318bee16-9c6e-4809-8ba1-8e58c6d0d6ee'

export default {
  methods: {
    refreshPage() {
      this.show = false;
      window.location.reload();
    }
  },
  data () {
    return {
      show: true
    }
  },
  computed: {
    deepLinkUrl () {
      return `https://${this.$store.getters.shopifyIntegration?.identifier}/admin/themes/current/editor?context=apps&activateAppId=${themeAppUuid}/cdseo`
    }
  }
}
</script>
